import React, { useEffect } from "react"
import moment from "moment"

import Button from "@material-ui/core/Button"

import DropdownCard from "./DropdownCard"

const DropdownButton = ({
  classStyle,
  onClickEdit,
  setNotificationMessage,
  setNotificationStatus,
  setNotificationOpen,
  cancelAppointment,
  updateAppointments,
  trueEndTime,
  appointment,
  inPersonOrTelehealth,
}) => {
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef(null)
  const today = moment()

  const disableEditButton = moment(trueEndTime) <= today

  function handleClick(e) {
    if (!e.target.closest(`.${drop.current.className}`) && open) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })

  return (
    <div className="dropdown" style={{ position: "relative" }} ref={drop}>
      <Button
        onClick={() => setOpen((open) => !open)}
        variant="contained"
        color="primary"
        className={classStyle}
      >
        Appointment options
      </Button>
      {open && (
        <DropdownCard
          inPersonOrTelehealth={inPersonOrTelehealth}
          classStyle={classStyle}
          setOpen={setOpen}
          onClickEdit={onClickEdit}
          setNotificationMessage={setNotificationMessage}
          setNotificationStatus={setNotificationStatus}
          setNotificationOpen={setNotificationOpen}
          cancelAppointment={cancelAppointment}
          appointment={appointment}
          updateAppointments={updateAppointments}
          disableEditButton={disableEditButton}
        />
      )}
    </div>
  )
}

export default DropdownButton
