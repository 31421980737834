import { FormControl, InputLabel, Select } from "@material-ui/core";
import React from "react";

const SelectInput = ({
  options = [],
  setValue,
  value,
  label,
  name,
  id,
  readOnly,
  loading = false,
}) => {
  const handleSelect = (e) => {
    setValue(e.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        fullWidth
        readOnly={readOnly}
        native
        value={value}
        onChange={handleSelect}
        label={label}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        <option aria-label="None" value="" />
        {loading ? (
          <>
            <option aria-label="None" value="">
              loading...
            </option>
          </>
        ) : (
          <>
            {options.map((_option) => {
              if (_option.title != "Hotline") {
                return (
                  <option
                    key={_option.title}
                    value={_option.value ? _option.value : _option.title}
                  >
                    {_option.title}
                  </option>
                );
              }
            })}
            ))}
          </>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
