import React, { useEffect, useState, createRef } from "react"
import PropTypes from "prop-types"
import { getDimensions } from "components/Calendar/utils"
import CircularProgress from "@material-ui/core/CircularProgress"

function Form({ src, title, iFrameId }) {
  const formRef = createRef()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [loading, setLoading] = useState(true)

  const hideSpinner = () => {
    setLoading(false)
  }

  useEffect(() => {
    const { width, height } = getDimensions(formRef.current)
    setDimensions({ width, height })
    var ifr = document.getElementById(iFrameId)
    if (window.location.href && window.location.href.indexOf("?") > -1) {
      var get = window.location.href.substr(
        window.location.href.indexOf("?") + 1
      )
      if (ifr && get.length > 0) {
        var src = ifr.src
        src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get
        ifr.src = src
      }
    }
    window.handleIFrameMessage = function (e) {
      if (typeof e.data === "object") {
        return
      }
      var args = e.data.split(":")
      var iframe
      args[2] = iFrameId.split("-")[1]
      if (args.length > 2) {
        iframe = document.getElementById(
          "JotFormIFrame-" + args[args.length - 1]
        )
      } else {
        iframe = document.getElementById("JotFormIFrame")
      }
      if (!iframe) {
        return
      }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView()
          break
        case "setHeight":
          iframe.style.height = args[1] + "px"
          break
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px"
          }
          break
        case "reloadPage":
          window.location.reload()
          break
        case "loadScript":
          var src = args[1]
          if (args.length > 3) {
            src = args[1] + ":" + args[2]
          }
          var script = document.createElement("script")
          script.src = src
          script.type = "text/javascript"
          document.body.appendChild(script)
          break
        case "exitFullscreen":
          if (window.document.exitFullscreen) window.document.exitFullscreen()
          else if (window.document.mozCancelFullScreen)
            window.document.mozCancelFullScreen()
          else if (window.document.mozCancelFullscreen)
            window.document.mozCancelFullScreen()
          else if (window.document.webkitExitFullscreen)
            window.document.webkitExitFullscreen()
          else if (window.document.msExitFullscreen)
            window.document.msExitFullscreen()
          break
      }
      var isJotForm = e.origin.indexOf("jotform") > -1 ? true : false
      if (
        isJotForm &&
        "contentWindow" in iframe &&
        "postMessage" in iframe.contentWindow
      ) {
        var urls = {
          docurl: encodeURIComponent(document.URL),
          referrer: encodeURIComponent(document.referrer),
        }
        iframe.contentWindow.postMessage(
          JSON.stringify({ type: "urls", value: urls }),
          "*"
        )
      }
    }
    if (window.addEventListener) {
      window.addEventListener("message", window.handleIFrameMessage, false)
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", window.handleIFrameMessage)
    }
  }, [])
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="primary" size={128} />
        </div>
      ) : null}
      <iframe
        ref={formRef}
        id={iFrameId}
        title="Make an Appointment at BOI"
        // onload="window.parent.scrollTo(0,0)"
        onLoad={hideSpinner}
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src={src}
        frameborder="0"
        style={{
          minWidth: "100%",
          border: "none",
        }}
        scrolling="yes"
        hidden={loading}
      ></iframe>
    </>
  )
}

Form.propTypes = {}

export default Form
