/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Cached, Cancel, Error, Save, CalendarToday } from "@material-ui/icons";
import { saveClinicSettings, getClinicSettings } from "../../apis";
import { AuthorizationContext } from "components/App";
import ErrorNotification from "components/common/ErrorNotification";
import { useNavigate } from "react-router-dom";
import initialSettings from "./initialSettings.json";
import SettingsForm from "./SettingsForm";
import { SelectInput } from "./SettingsForm/Inputs/Common";

const sleep = (time) => new Promise((res) => setTimeout(res, time));

const Settings = () => {
  const { filterOptions, role } = useContext(AuthorizationContext);
  const navigate = useNavigate();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [clinicOptions, setClinicOptions] = useState([]);
  const [clinic, setClinic] = useState("");
  const [showSettings, setShowSettings] = useState("");
  const [saving, setSaving] = useState(false);
  // const [schedulingType, setSchedulingType] = useState("provider");
  const [settingsValues, setSettingsValues] = useState(initialSettings);
  const [errorMessage, setErrorMessage] = useState("");
  const settingsRef = useRef(initialSettings);

  const settingsChanged = Object.keys(settingsValues).filter(
    (key) => settingsValues[key] !== settingsRef.current[key]
  );

  const filtersBeingLoaded = Object.keys(filterOptions).length === 0;

  const handleSubmitClinicSettings = async (e) => {
    e.preventDefault();

    setSaving(true);

    const query = settingsChanged.map((key) => {
      return {
        action: key,
        value: settingsValues[key],
        r_qID: settingsValues["r_qID"],
        clinic,
      };
    });

    try {
      await saveClinicSettings(query);
      settingsRef.current = settingsValues;
      setSaving(false);
      setNotificationMessage("Settings Saved Successfully...");
      setNotificationStatus("success");
      setNotificationOpen(true);

      await sleep(2500);
      setNotificationOpen(false);
    } catch (error) {
      setSaving(false);
      setNotificationMessage("SERVER ERROR...");
      setNotificationStatus("error");
      setNotificationOpen(true);

      await sleep(2500);
      setNotificationOpen(false);

      console.error(error);
    }
  };

  const loadClinicSettings = async () => {
    if (!clinic) return;
    setShowSettings("loading");
    setSettingsValues(initialSettings);
    settingsRef.current = initialSettings;

    try {
      // const clinicName =
      //   schedulingType === "provider" ? clinic : `${clinic} Self`;

      const clinicSettings = await getClinicSettings(clinic);

      settingsRef.current = clinicSettings;
      setSettingsValues(clinicSettings);
      setShowSettings("ready");
    } catch (err) {
      setShowSettings("error");

      if (!err?.response?.data.status) return console.error(err);

      setErrorMessage(err.response.data.status);
    }
  };

  const handleResetButton = async () => {
    await loadClinicSettings();
    setNotificationMessage(
      "Category Settings has been reset to previous state..."
    );
    setNotificationStatus("info");
    setNotificationOpen(true);

    await sleep(2500);
    setNotificationOpen(false);
  };

  const getClinicOptions = () => {
    if (filtersBeingLoaded) return;

    const clinicOptionsArr = filterOptions.clinicName.map((clinic) => {
      return { title: clinic };
    });

    setClinicOptions(clinicOptionsArr);
  };

  useEffect(() => {
    loadClinicSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinic,
    // schedulingType
  ]);

  useEffect(() => {
    getClinicOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  if (!role || filtersBeingLoaded) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <CircularProgress size={100} />
        </Grid>
      </Grid>
    );
  }

  if (role !== "admin") {
    navigate("/");
  }

  let clinicJotSettings;

  if (showSettings === "loading") {
    clinicJotSettings = (
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "8rem" }}
          alignContent="center"
        >
          <CircularProgress size={80} />
        </Grid>
      </Grid>
    );
  } else if (showSettings === "ready") {
    clinicJotSettings = (
      <SettingsForm
        settingsValues={settingsValues}
        setSettingsValues={setSettingsValues}
      />
    );
  } else if (showSettings === "error") {
    clinicJotSettings = (
      <Grid item>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Error color="secondary" fontSize="large" />
          <Typography variant="h6">
            {errorMessage
              ? errorMessage
              : "There has been an error, please try again later..."}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    clinicJotSettings = undefined;
  }

  return (
    <Container fluid maxWidth>
      <form onSubmit={handleSubmitClinicSettings}>
        <Box paddingY={4}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item style={{ position: "relative", right: "90px" }}>
              <Button
                startIcon={<CalendarToday />}
                variant="contained"
                type="button"
                color="primary"
                onClick={() => navigate("/")}
                fullWidth
              >
                Back To Calendar
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              spacing={2}
              xs
              md={8}
              style={{ position: "relative", right: "90px" }}
            >
              <Grid item xs={12}>
                <SelectInput
                  options={clinicOptions}
                  name="clinic"
                  id="clinic"
                  label="Select Categories"
                  setValue={setClinic}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Scheduling Type"
                        name="Scheduling Type"
                        value={schedulingType}
                        onChange={(e) => setSchedulingType(e.target.value)}
                        row
                      >
                        <FormControlLabel
                          value="provider"
                          control={<Radio color="primary" />}
                          label="Provider"
                        />
                        <FormControlLabel
                          value="self"
                          control={<Radio color="primary" />}
                          label="Self"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12} sm={6} md>
                {/* <Button
                  startIcon={<Cancel />}
                  variant="contained"
                  type="button"
                  color="secondary"
                  onClick={() => navigate("/")}
                  fullWidth
                >
                  Cancel
                </Button> */}
              </Grid>
              <Grid item xs={12} sm={6} md lg={3}>
                <Button
                  disabled={settingsChanged.length <= 0 || saving}
                  startIcon={<Save />}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md lg={3}>
                <Button
                  disabled={settingsChanged.length <= 0 || saving}
                  startIcon={<Cached />}
                  variant="contained"
                  type="button"
                  onClick={handleResetButton}
                  fullWidth
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {clinicJotSettings}
        </Box>
      </form>
      <ErrorNotification
        open={notificationOpen}
        title={notificationMessage}
        onClose={() => setNotificationOpen(false)}
        severity={notificationStatus}
      />
    </Container>
  );
};

export default Settings;
