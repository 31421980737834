import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 'none',
  },
  switchLabel: {
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
  leftContainer: {
    flex: 'none',
    width: 'auto',
  },
  rightContainer: {
    paddingLeft: theme.spacing(2),
    zIndex: 3,
  },
  settingsButton: {
    ...theme.typography.button,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1px',
    textDecoration: 'none',
    transition: 'all 0.3s ease-in-out',
    padding: '4px 6px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))
