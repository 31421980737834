import { GET, POST } from "utils/httpMethods"
import axios, { CancelToken } from "axios"
import cloudFunctions from "./cloudfunctions.json"

let cancelToken
let keyAPI = "?key=AIzaSyCcZqJxQr8cew_LA_dYrFxoD8Yj7gY9La8"
let keyAPIProd = "?key=AIzaSyBYVordko3TJjoTZXYzggQUX8YZ5qrvCNw"

// TODO: Hablar con los muchachos acerca de la api key para los appointments

const endpoints =
  process.env.NODE_ENV === "production"
    ? cloudFunctions.production
    : cloudFunctions.development

export async function getAppointments({ startDate, endDate }, authToken) {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel()
  }
  cancelToken = CancelToken.source()
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/GetBSTIAppointment${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-All-BSTIAppointment`

    const response = await GET(endpoints.getAppointments, {
      // headers: {
      //  Authorization: `${authToken}`,
      // },
      params: {
        startDate,
        endDate,
      },
      cancelToken: cancelToken.token,
    })

    return { message: response.data }
  } catch (e) {
    throw { message: e.message, cancelToken }
  }
}

export async function searchAppointments(searchText, authToken) {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/SearchBSTIAppointments${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-Search-BSTIAppointment`
    const response = await GET(endpoints.searchAppointments, {
      // headers: {
      // Authorization: `${authToken}`,
      // },
      params: {
        searchText,
      },
    })
    return { message: response.data }
  } catch (e) {
    throw { message: e.message }
  }
}

export async function filterAppointments({
  startDate,
  authToken,
  endDate,
  clinicName = "",
  apptType = "",
  provider = "",
}) {
  try {
    // provider = provider.replace(',', '')
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/FilterBSTIAppointments${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-BSTIAppointment-ByClinic`\

    const response = await GET(endpoints.filterAppointments, {
      // headers: {
      // Authorization: `${authToken}`,
      // },
      params: {
        startDate,
        endDate,
        clinicName,
        apptType,
        provider,
      },
    })
    return { message: response.data }
  } catch (e) {
    throw { message: e.message }
  }
}

export async function getFilterOptions() {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/GetClinicNameApptTypeProvider${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-BSTIClinicsList`
    const response = await GET(endpoints.getFilterOptions)
    return { message: response.data }
  } catch (e) {
    throw { message: e.message }
  }
}

export async function sendReminder(appointmentID) {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${reminderURL}/Scheduler/SendReminder${keyAPIProd}`
    //     : `${apiUrls.API}/NYC-B-Message-Trigger-ResendReminder`
    const rawResponse = await fetch(endpoints.sendReminder, {
      method: "POST",
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      },
      body: JSON.stringify({
        type: "Reminder-Patient",
        appointmentID,
      }),
    })

    // const content = await rawResponse.json();

    return true
    // console.log(content);

    // return {message: content.data}
  } catch (e) {
    console.error(e.message)
    return false
  }
}

export async function CancelAppointment(apptData) {
  try {
    await fetch(endpoints.cancelAppointment, {
      method: "POST",
      mode: "no-cors",
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json',
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "*"
      // },
      body: JSON.stringify({
        ...apptData,
        bodyType: "",
      }),
    })

    return true
  } catch (e) {
    console.error(e.message)
    return false
  }
}

export const getRole = async (token) => {
  try {
    const res = await axios.post(endpoints.getRole, {
      token,
    })
    return res.data.role
  } catch (error) {
    console.error(error)
  }
}

export const saveClinicSettings = async (query) => {
  try {
    const res = await axios.post(endpoints.saveClinicSettings, query)
    return res
  } catch (error) {
    throw error
  }
}

export const getClinicSettings = async (clinicName) => {
  try {
    const res = await axios.post(endpoints.getClinicSettings, {
      clinic_name: clinicName,
    })

    return res.data
  } catch (error) {
    throw error
  }
}
