import React, { useState, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Typography, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchBar from "./SearchBar";
import useStyles from "../ToolBar/style";
import moment from "moment";
import Filter from "./Filter";
import Hidden from "@material-ui/core/Hidden";

import { Settings } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthorizationContext } from "components/App";
import { useRef } from "react";

const initialFilters = {
  apptType: [],
  clinicName: [],
  provider: [],
};
function ToolBar({
  calendarApi,
  onClickSchedule,
  getAppointments,
  currentView,
  setCurrentView,
  onClickEdit,
  currentDate,
  setCurrentDate,
  filterOptions,
  activeFilters,
  setActiveFilters,
  updateAppointments,
}) {
  const classes = useStyles();
  const [title, setTitle] = useState(null);
  const { role } = useContext(AuthorizationContext);
  const lastFiltersApplied = useRef(initialFilters);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    updateTitle();
  }, [calendarApi]);
  useEffect(() => {
    updateTitle();
  }, [currentView]);

  const handleChange = ({ filter, type }) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: prevFilters[type].map((option) =>
        filter.value === option.value ? filter : option
      ),
    }));
  };

  const disabledApply = () => {
    if (!Object.keys(filterOptions).length) return true;

    return (
      Object.keys(filters).reduce((acc, type) => {
        return [
          ...acc,
          ...filters[type].filter(
            (option, idx) =>
              option.checked !== lastFiltersApplied.current[type][idx].checked
          ),
        ];
      }, []).length <= 0
    );
  };

  const handleActiveFilters = () => {
    const queryFilters = Object.keys(filters).reduce((acc, filterType) => {
      const checkedFilters = filters[filterType].filter(
        (option) => option.checked
      );

      return {
        ...acc,
        [filterType]: checkedFilters.map((option) => option.value).join(":"),
      };
    }, {});

    lastFiltersApplied.current = filters;
    setActiveFilters(queryFilters);
  };

  const formattedFilters = () => {
    return Object.keys(filterOptions).reduce((acc, filterType) => {
      acc[filterType] = filterOptions[filterType].map((option) => ({
        value: option,
        checked: false,
      }));

      if (filterType === "provider") {
        acc[filterType] = filterOptions[filterType].map((option) => ({
          value: option,
          checked: false,
        }));

        acc[filterType].unshift({ value: "Unspecified", checked: false });
      }

      return acc;
    }, {});
  };

  const resetAllFilter = () => {
    setFilters(formattedFilters);
  };

  const resetFilterByType = (type) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: prevFilters[type].map((option) => ({
        ...option,
        checked: false,
      })),
    }));
  };

  const updateTitle = () => {
    if (calendarApi) {
      const { viewTitle } = calendarApi.getCurrentData();
      setTitle(viewTitle);
    }
  };
  const onClickPrevious = () => {
    calendarApi.prev();
    let newDate;
    switch (currentView) {
      case "dayGridMonth":
        newDate = moment(currentDate).subtract("1", "months");
        break;
      case "listWeek":
        newDate = moment(currentDate).subtract("1", "weeks");
        break;
      case "timeGridDay":
        newDate = moment(currentDate).subtract("1", "day");
        break;
    }
    getAppointments({ baseDate: newDate });
    setCurrentDate(newDate.format());
    updateTitle();
  };
  const onClickNext = () => {
    calendarApi.next();
    let newDate;
    switch (currentView) {
      case "dayGridMonth":
        newDate = moment(currentDate).add("1", "months");
        break;
      case "listWeek":
        newDate = moment(currentDate).add("1", "weeks");
        break;
      case "timeGridDay":
        newDate = moment(currentDate).add("1", "day");
        break;
    }
    getAppointments({ baseDate: newDate });
    setCurrentDate(newDate.format());
    updateTitle();
  };
  const onClickToday = () => {
    calendarApi.today();
    getAppointments({ baseDate: moment() });
    setCurrentDate(moment());
    updateTitle();
  };
  const isTodayDisabled = useMemo(() => {
    if (calendarApi) {
      const { start, end } =
        calendarApi.getCurrentData().dateProfile.activeRange;
      if (moment().isBetween(start, end)) {
        return true;
      }
    }
    return false;
  }, [title]);

  useEffect(() => {
    if (!Object.keys(filterOptions).length) return;

    lastFiltersApplied.current = formattedFilters();
    setFilters(formattedFilters());
  }, [filterOptions]);

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid
        container
        wrap="nowrap"
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            {title && (
              <Typography variant="h1" noWrap>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={onClickPrevious}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton onClick={onClickNext}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          {role === "admin" && (
            <Grid item>
              <Link to="settings" className={classes.settingsButton}>
                <Settings color="action" /> Settings
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Grid
          container
          spacing={2}
          className={classes.leftContainer}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Button
              onClick={onClickToday}
              variant="outlined"
              color={isTodayDisabled ? "secondary" : "primary"}
              disabled={isTodayDisabled}
            >
              Today
            </Button>
          </Grid>
          {currentView === "listWeek" && (
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center">
                <Button
                  onClick={() => {
                    let newDate = currentDate;
                    if (
                      !moment(currentDate).isSame(
                        moment(currentDate).endOf("week"),
                        "month"
                      )
                    ) {
                      newDate = moment(
                        calendarApi.currentDataManager.data.currentDate
                      );
                    }
                    setCurrentView("dayGridMonth");
                    setCurrentDate(newDate);
                    getAppointments({
                      baseDate: newDate,
                      currentView: "dayGridMonth",
                    });
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Back to Month View
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          item
          container
          wrap="nowrap"
          alignItems="center"
          spacing={2}
          justifyContent="flex-end"
          className={classes.rightContainer}
        >
          <Grid sm item>
            <SearchBar
              updateAppointments={updateAppointments}
              onClickEdit={onClickEdit}
              view={currentView}
            />
          </Grid>
          <Hidden smDown>
            <Grid sm={3} item>
              <Filter
                id="clinic"
                label="Filter Categories"
                onChange={(filter) =>
                  handleChange({ filter, type: "clinicName" })
                }
                resetFilter={() => resetFilterByType("clinicName")}
                options={filters.clinicName.sort((a, b) =>
                  a.value.localeCompare(b.value, undefined, {
                    sensitivity: "base",
                  })
                )}
              />
            </Grid>
            {/* <Grid sm={3} item>
              <Filter
                id="appointmentType"
                label="Filter Appointment Type"
                onChange={(filter) =>
                  handleChange({ filter, type: "apptType" })
                }
                resetFilter={() => resetFilterByType("apptType")}
                options={filters.apptType}
              />
            </Grid> */}
            <Grid sm={3} item>
              <Filter
                id="provider"
                label="Filter Provider"
                onChange={(filter) =>
                  handleChange({ filter, type: "provider" })
                }
                resetFilter={() => resetFilterByType("provider")}
                options={filters.provider}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disabled={disabledApply()}
                onClick={handleActiveFilters}
              >
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={resetAllFilter}
              >
                Reset
              </Button>
            </Grid>
          </Hidden>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickSchedule}
            >
              Schedule
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ToolBar.propTypes = {};

export default ToolBar;
